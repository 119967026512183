$color-one: #000;
$color-two: #621302;
$color-three: #d65430;
$color-four: #ef7d01;
$color-five: #f8bd55;
$body-color: white;
$bg-color: $color-one;

@import url("https://fonts.googleapis.com/css?family=Raleway:400,300,600");
@import url("css/normalize.css");
@import url("css/skeleton.css");

html,
body {
  color: $body-color;
  background-color: $bg-color;
  font-family: Raleway, sans-serif;
}

.color-one {
  color: $color-one;
}

.color-two {
  color: $color-two;
}

.color-three {
  color: $color-three;
}

.color-four {
  color: $color-four;
}

.color-five {
  color: $color-five;
}

.color-grey {
  color: #888;
}

.page-center {
  margin-top: 25%;
}

.page-top {
  margin-top: 5%;
}

.diet-container {
  padding: 16px;
  margin-bottom: 4px;
}
